import React, { useState } from 'react';
// Material Styles
import { fade, makeStyles, withStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import './assets/styles/styles.css';
// Material Icons
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DescriptionIcon from '@material-ui/icons/Description';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// Images
import logoGovCo from '../src/assets/img/logoGovCo.png';
import logoGovCoFooter from '../src/assets/img/logoGovCoFooter.png';
import logoSfc from '../src/assets/img/logo-sfc-comple.png';
import logoSfFooter from '../src/assets/img/logo-sfc.png';
// Material Components
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { ItemList } from './components/ItemList';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
// Libraries
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// Components
import { ListItemLink } from './components/ItemList';
// Rxjs
import { ajax } from 'rxjs/ajax';

const MySwal = withReactContent(Swal);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3366cc',
    },
    secondary: {
      main: '#6c757d',
    },
    text: {
      main: '#fff'
    }
  },
});

const WhiteTextTypography = withStyles({
  root: {
    color: "#1E4880"
  }
})(Typography);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(12),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  footer: {
    marginTop: 15,
    fontSize: 15
  },
  grow: {
    flexGrow: 1,
  }
}));

const App = () => {

  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [resp, setResp] = useState([]);

  const handleOnSubmit = async (e) => {
    if (e.keyCode === 13) {
      const { response } = await ajax
        .getJSON(`${process.env.REACT_APP_API_URL_BASE}/find?search=${search}&code=${process.env.REACT_APP_FIND_CODE}`)
        .toPromise();

      if (response.length === 0) {
        MySwal.fire(<p>No se logro encontrar el archivo con el nombre {search}</p>);
        setSearch("");
      } else {
        setResp(response);
      }
    }
  }

  const handleOnChange = ({ target }) => {

    if (target.value === "") {
      setResp([]);
      setSearch("");
    } else {
      setSearch(target.value);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.grow}>
        <AppBar color="primary" position="static">
          <Toolbar>
            <IconButton onClick={() => window.location.reload(false)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <img width="150" alt="Logo GOV" src={logoGovCo} />
            </IconButton>
            <Typography className={classes.title} variant="h6" noWrap>

            </Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={search}
                onKeyUp={handleOnSubmit}
                onChange={handleOnChange}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </Toolbar>
        </AppBar>

        <Container style={{ marginBottom: 150 }} >
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={8}>
              <img style={{ marginTop: "30px" }} alt="Logo SFC" src={logoSfc} />
            </Grid>
            <Grid item xs={8}>
              <WhiteTextTypography variant="h5" align="center">
                Documentos Públicos de la Superintendencia Financiera de Colombia
              </WhiteTextTypography>
            </Grid>
          </Grid>
          <hr />
          <Box my={2}>
            {search !== "" ?
              resp.map(({ url, file_name, path }, idx) => (
                <ListItemLink key={idx} href={url} target="_blank" download={file_name} >
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary={file_name} secondary={path} />
                </ListItemLink>
              )) :
              <ItemList />
            }
          </Box>
        </Container>

        <div style={{
          height: 500,
          width: '100%',
          margin: 0,
          position: 'relative',
          paddingLeft: 68, paddingRight: 55,
          backgroundColor: '#069169'
        }}>
          <Card style={{
            position: 'absolute',
            width: '90%',
            height: 570,
            bottom: -10,
            top: -100
          }}>
            <CardContent>
              <div>
                <div className='row'>
                  <div className='column'>
                    <div>
                      <Typography style={{ color: '#004884' }} variant="h6" color="primary" component="h2">
                        Superintendencia Financiera de Colombia
                      </Typography>
                      <Typography style={{ color: '#004884' }} className={classes.footer} variant="h6" color="primary" component="h5">
                        Sede Principal
                      </Typography>
                    </div>
                  </div>
                  <div className='column'>
                    <div >
                      <img alt="Logo Super Financiera" className="image-footer" height='80' src={logoSfFooter} />
                    </div>
                  </div>
                </div>
              </div>
              <Typography className={classes.footer} color="textSecondary">
                Dirección: Calle 7 No. 4 - 49 Bogotá, D.C.
              </Typography>
              <Typography className={classes.footer} color="textSecondary">
                Conmutador: +57(1) 594 02 00 - 594 02 01 - 350 8166
              </Typography>
              <Typography className={classes.footer} color="textSecondary">
                Fax: +57(1) 350 79 99 - 350 5707
              </Typography>
              <Typography className={classes.footer} color="textSecondary">
                Centro de Contacto: +57 (1) 307 8042
              </Typography>
              <Typography className={classes.footer} color="textSecondary">
                Lunes a viernes (días hábiles) de 7:30 a.m. a 9:00 p.m. jornada continua.
                <br />
                Sábados de 8:00 a.m. a 4:00 p.m. jornada continua.
              </Typography>
              <Typography className={classes.footer} color="textSecondary">
                Línea gratuita nacional: 018000 120 100
              </Typography>
              <Typography className={classes.footer} color="textSecondary">
                Correo institucional: super@superfinanciera.gov.co
              </Typography>
              <Typography className={classes.footer} color="textSecondary">
                Correo electrónico para notificaciones: notificaciones_ingreso@superfinanciera.gov.co
              </Typography>

              <div className="col-12 social_link">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <span className="fa fa-twitter">

                    </span><a className="section" href="https://twitter.com/SFCsupervisor" title="Enlace a @SFCsupervisor">
                      <strong>@SFCsupervisor</strong>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <span className="fa fa-instagram">

                    </span>
                    <a className="section" href="https://www.instagram.com/superfinanciera/" title="Enlace a @superfinanciera">
                      <strong>superfinanciera</strong>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <span className="fa fa-facebook"> </span>
                    <a className="section" href="https://www.facebook.com/superintendencia.financiera." title="Enlace a @Superintendencia Financiera de Colombia">
                      <strong>Superintendencia Financiera de Colombia</strong> </a>
                  </li>
                </ul>
              </div>

              <CardMedia
                className={classes.cover}
                image={logoSfFooter}
                title="Live from space album cover"
              />

            </CardContent>
            <CardActions style={{ marginBottom: 30 }}>
              <a className="btn-small" href="https://www.superfinanciera.gov.co/inicio/nuestra-entidad/acerca-de-la-sfc/politicas-de-la-entidad/politicas-web-10106783" >
                Políticas
              </a>

              <a className="btn-small" href="https://www.superfinanciera.gov.co/jsp/Publicaciones/mapasitio/loadMapaSitio/s/0/f/0/c/0" >
                Mapa del sitio
              </a>
            </CardActions>
          </Card>
        </div>

        <React.Fragment>
          <CssBaseline />
          <AppBar color="primary" position="relative">
            <Toolbar>
              <IconButton onClick={() => window.location.reload(false)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <img width="150" alt="Logo GOV" src={logoGovCoFooter} />
              </IconButton>
              <Typography className={classes.title} variant="h6" noWrap>

              </Typography>
            </Toolbar>
          </AppBar>
        </React.Fragment>
      </div>
    </ThemeProvider>
  );

}

export default App;