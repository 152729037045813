import React, { useEffect, useState } from 'react';
// Material Styles
import { makeStyles } from '@material-ui/core/styles';
// Material Components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
// Rxjs
import { ajax } from 'rxjs/ajax';
import { Subscription } from 'rxjs';
import { pluck } from 'rxjs/operators';
// External Libs
import FolderTree from 'react-folder-tree';
import 'react-folder-tree/dist/style.css';
import '../assets/styles/styles.css';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));


export const ListItemLink = (props) => {
    return <ListItem button component="a" {...props} />
}

export const ItemList = () => {

    const classes = useStyles();
    const [data, setData] = useState([]);

    const onNameClick = async ({ nodeData }) => {
        const { name, url, type, extension } = nodeData;
        const element = document.createElement("a");

        if (type === 'file' && extension === 'txt') {
            const response = await fetch(url, { method: 'GET' });
            const file = new Blob([await response.blob()], { type: 'text/plain' });
            element.href = URL.createObjectURL(file);
            element.download = name;
            document.body.appendChild(element);
            element.click();
        }

        if (type === 'file' && extension !== 'txt') {
            element.href = url;
            element.target = '_blank';
            element.download = name;
            document.body.appendChild(element);
            element.click();
        }

        element.parentNode.removeChild(element);
    };

    useEffect(() => {
        const subscription = new Subscription();
        const url = `${process.env.REACT_APP_API_URL_BASE}/getAllFiles?code=${process.env.REACT_APP_GET_ALL_FILES_CODE}`
        subscription.add(ajax.getJSON(url).pipe(pluck('response')).subscribe(setData));
        return () => subscription.unsubscribe();
    }, []);

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <div className={classes.demo}>
                        <List>
                            {
                                data.map((value, i) => (<FolderTree
                                    key={i}
                                    data={value}
                                    initOpenStatus='closed'
                                    showCheckbox={false}
                                    indentPixels={20}
                                    onNameClick={onNameClick}
                                    readOnly
                                />))
                            }
                        </List>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}